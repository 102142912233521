.grid-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  justify-content: center;
  padding: 10px;
}

.add-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  background-color: #d1e7dd;
  color: #0f5132;
}

.add-tile:hover {
  background-color: #badbcc;
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
    padding: 8px;
  }

  .tile {
    width: 80px;
    height: 80px;
  }
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent body scroll */
  font-family: 'Comic Sans MS', 'Chalkboard SE', sans-serif;
  background-color: #F5F5F5;
}

.app-header {
  flex-shrink: 0; /* Prevent header from shrinking */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  z-index: 1001;
}

.brand-logo {
  height: 40px;
  margin-right: 1rem;
}

.app-title {
  flex-grow: 1;
  margin: 0;
}

.settings-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  z-index: 1001;
}

.settings-button:hover {
  opacity: 0.8;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* Important for nested flex scrolling */
  padding: 1rem;
}

.tiles-grid {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.add-tile-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6f4ea;
  border: 2px dashed #34a853;
  border-radius: 8px;
  cursor: pointer;
  height: 150px;
  transition: background-color 0.3s ease;
}

.add-tile-button:hover {
  background-color: #d4edda;
}

.plus-icon {
  font-size: 3rem;
  color: #34a853;
}

.add-tile-button span:last-child {
  margin-top: 0.5rem;
  color: #34a853;
  font-weight: bold;
}

.category-bar {
  flex-shrink: 0; /* Prevent shrinking */
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.category-chip {
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  background-color: #e0e0e0;
  border: none;
  border-radius: 16px;
  cursor: pointer;
}

.category-chip.active {
  background-color: #FFB7B2;
  color: white;
}

.bottom-bar {
  flex-shrink: 0; /* Prevent shrinking */
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: #7FDBDA;
}

.tab-button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.tab-button.active {
  border-bottom: 2px solid white;
}

.statistics-placeholder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .tiles-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 999;
}

.sidebar.open {
  transform: translateX(0);
}

.menu-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.menu-toggle:hover {
  background-color: #0056b3;
}

.profile-info {
  text-align: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  border: 3px solid #007bff;
}

/* Remove or comment out these styles if they exist
.add-tile-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #28a745;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.add-tile-button:hover {
  background-color: #218838;
}
*/

/* Adjust main content when sidebar is open */
.sidebar.open + .main-content {
  margin-right: 300px;
}

/* Theme styles */
.default {
  background-color: #ffffff;
  color: #000000;
}

.dark {
  background-color: #333333;
  color: #ffffff;
}

.light {
  background-color: #f0f0f0;
  color: #333333;
}

/* Accessibility styles */
.high-contrast {
  background-color: #000000;
  color: #ffffff;
}

.large-text {
  font-size: 1.2em;
}

.brand-logo {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }

  .sidebar {
    width: 100%;
  }

  .brand-logo {
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  }

  .menu-toggle {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .brand-logo {
    max-width: 120px;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.centered-logo {
  max-width: 150px;
  height: auto;
}

/* Add or update these styles */
.tile-grid-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  /* Add smooth scrolling */
  scroll-behavior: smooth;
  /* Hide scrollbar for Chrome/Safari/Opera */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  /* Hide scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.last-played-words {
  flex-shrink: 0; /* Prevent shrinking */
}
