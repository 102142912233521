.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f7fa;
    text-align: center;
  }
  
  .login-logo {
    width: 150px;
    filter: brightness(120%);
    margin-bottom: 20px;
  }
  
  .login-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
    max-width: 500px;
    line-height: 1.5;
  }
  
  .login-button {
    font-size: 1.5rem;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  